import { lazy, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import i18n, { i18nInit } from '@cortex/locales/i18n/i18n';
import * as Sentry from '@sentry/react';

import ROUTES from './common/constants/routes';
import { getPreferredLanguage } from './common/locales/locales-utils';
import {
  useCreateUser,
  useMyUser,
  useRequestVerifyEmail,
  useSetUserPassword,
  useVerifyEmail,
} from './network/api/users';
import { useGoogleAnalytics } from './ui/components';
import { Header } from './views/shared/Header';
import config from './config';

const PublicRoutesContainer = lazy(
  () => import('./ui/components/PublicRoutesContainer'),
);
const Login = lazy(() => import('./views/Login'));
const VerifyEmail = lazy(() => import('./ui/components/VerifyEmail'));
const VerifyUserInformation = lazy(
  () => import('./views/VerifyUserInformation'),
);
const VerifyUserFailed = lazy(() => import('./views/VerifyUserFailed'));
const SetPass = lazy(() => import('./ui/components/SetPass'));
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

i18nInit();
export function PublicRouter() {
  const { data: userData, error } = useMyUser();

  const { mutateAsync: createUser } = useCreateUser();
  const { mutateAsync: setUserPassword } = useSetUserPassword();

  useGoogleAnalytics(config.ga.trackingCode);
  const changeLanguage = async (language: string) => {
    await i18n.changeLanguage(language);
  };
  const language = getPreferredLanguage(userData, localStorage);

  useEffect(() => {
    if (language) {
      changeLanguage(language);
    }
  }, [language]); // eslint-disable-line

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  return (
    <PublicRoutesContainer>
      <Header />
      <div className="bg-white lg:max-w-[37.5rem] lg:mx-auto">
        <SentryRoutes>
          <Route path="/" element={<Outlet />}>
            <Route index element={<Login />} />
            <Route
              path="forgot-password"
              element={
                <VerifyEmail
                  useVerifyEmail={useVerifyEmail}
                  useRequestVerifyEmail={useRequestVerifyEmail}
                />
              }
            />
            <Route
              path="set-email/:customerToken"
              element={
                <VerifyEmail
                  useVerifyEmail={useVerifyEmail}
                  useRequestVerifyEmail={useRequestVerifyEmail}
                />
              }
            />
            <Route
              path="set-password/:token"
              element={
                <SetPass
                  createUser={createUser}
                  setUserPassword={setUserPassword}
                  isMerchant={false}
                />
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
          <Route path="/safeship" element={<Outlet />}>
            <Route index element={<Login />} />
          </Route>
          <Route
            path={ROUTES.VERIFY_INFORMATION}
            element={<VerifyUserInformation />}
          />
          <Route
            path={ROUTES.VERIFY_INFORMATION_FAILED}
            element={<VerifyUserFailed />}
          />
        </SentryRoutes>
      </div>
    </PublicRoutesContainer>
  );
}
