import type { ComponentPropsWithoutRef } from 'react';

import LoaderEllipsisSVG from './assets/LoaderEllipsis.svg';

export interface RoundedButtonProps extends ComponentPropsWithoutRef<'button'> {
  kind?:
    | 'dark'
    | 'violet'
    | 'orange'
    | 'green'
    | 'grey'
    | 'white'
    | 'red'
    | 'indigo'
    | 'purple';
  isFetching?: number | boolean;
}

const kindStyles: any = {
  indigo: 'bg-reguard-indigo border-reguard-indigo text-white',
  dark: 'bg-reguard-indigo-tint border-reguard-indigo-tint text-white',
  violet:
    'bg-reguard-violet text-white border-reguard-violet shadow-button hover:text-white hover:bg-reguard-violet-shade hover:border-reguard-violet-shade focus:bg-reguard-violet-tint disabled:bg-reguard-inactive1 disabled:border-reguard-inactive1 disabled:text-reguard-inactive2 disabled:cursor-default',
  orange:
    'bg-white text-reguard-indigo border-reguard-orange hover:text-white hover:bg-reguard-orange hover:border-reguard-orange focus:bg-[#FFF5ED] disabled:bg-white disabled:border-[#95A1B4] disabled:text-reguard-inactive1 disabled:cursor-default',
  green:
    'bg-reguard-wintergreen text-white border-reguard-wintergreen shadow-button hover:text-white hover:bg-reguard-wintergreen-shade hover:border-reguard-violet-shade focus:bg-reguard-violet-tint disabled:bg-reguard-inactive1 disabled:border-reguard-inactive1 disabled:text-reguard-inactive2 disabled:cursor-default',
  grey: 'bg-reguard-inactive1 border-reguard-inactive1 shadow-button',
  white:
    'bg-white text-reguard-indigo-tint border-reguard-indigo-tint hover:text-white hover:bg-reguard-indigo-tint hover:border-reguard-indigo-tint focus:bg-[#EDEFF2] focus:text-reguard-indigo-tint disabled:bg-white disabled:border-reguard-inactive1 disabled:text-reguard-inactive1 disabled:cursor-default',
  red: 'text-white bg-reguard-error hover:bg-reguard-error hover:border-reguard-error border-reguard-error active:bg-reguard-error active:border-reguard-error',
  purple:
    'bg-safeship-purple border-safeship-purple hover:bg-safeship-dark-purple hover:border-safeship-dark-purple text-white shadow-button hover:text-white disabled:bg-reguard-inactive1 disabled:border-reguard-inactive1 disabled:text-reguard-inactive2 disabled:cursor-default',
};

export const RoundedButton = ({
  isFetching = 0,
  kind = 'green',
  className,
  ...props
}: RoundedButtonProps) => {
  const btnChildren = isFetching ? (
    <span className="flex flex-row justify-center">
      <span className="h-5">
        <LoaderEllipsisSVG data-testid="loader-ellipsis" />
      </span>
    </span>
  ) : (
    props.children
  );

  return (
    <button
      {...props}
      className={`rounded-full cursor-pointer border-solid border-[1px] py-[0.625rem] min-w-[8rem] b2b leading-[1.125rem]
      hover:border-reguard-violet focus:border-reguard-violet focus-visible:border-reguard-violet
      focus:outline-reguard-violet focus-visible:outline-reguard-violet ${
        kindStyles[kind]
      } ${className || ''}`}
    >
      {btnChildren}
    </button>
  );
};
